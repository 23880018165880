<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="0"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb20 m1340 flex-col-stretch">
                <LeftNav :leftIndex="0" ></LeftNav>
                <div class="flex-grow-1 ml20 pt30 plr30 personal basic_info">
                    <div class="fs20 fwb pb20">{{ $t('basicinformation') }} </div>
                    <div class="flex-box flex-col-start">
                        <div>
                            <div class="fs16 col5 pb5">{{$t('registrationtime')}}</div>
                            <!-- <el-input  class="input mb30" placeholder="enter your name" v-model="user.createtime_text" :readonly="true"></el-input> -->
                            <div  class=" mb30" placeholder="enter your name" >{{ user.createtime_text }}</div>
                            <div class="fs16 col5 pb5">{{$t('email')}}</div>
                            <div  class=" mb30" placeholder="email" >{{ user.email }}</div>
                            <div class="fs16 col5 pb5">{{$t('inviteId')}}</div>
                            <div  class=" mb30" placeholder="inviteId" >{{ user.invite_id }}</div>
                            <div class="fs16 col5 pb5">{{$t('name')}}</div>
                            <!-- :readonly="user.nickname?true:false" -->
                            <el-input class="input mb30" placeholder="enter your name" v-model="nickname" ></el-input>
                            <div class="fs16 col5 pb5">{{ $t('birth') }}</div>
                            <!-- :disabled="user.birthday?true:false" -->
                            <el-date-picker v-model="birth" type="date" class="input mb30"
                                placeholder="choose birthday" value-format="yyyy-MM-dd" ></el-date-picker>
                            <div class="fs16 col5 pb5">{{ $t('gender') }}</div>
                            <!-- :disabled='user.gender?true:false' -->
                            <el-select v-model="gender" class="input mb30" placeholder="choose gender" >
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="pl110">
                            <div class="fs16 col5">{{$t('headsculpture')}}</div>
                            <img :src="avatar?avatar:require('../assets/static/icon/avatar.png')" class="avatar" alt="">
                            <div>
                                <el-upload class="avatar-uploader flex-box flex-center"
                                    action="" :show-file-list="false"
                                    :auto-upload="false"
                                    :on-change="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                    <img src="../assets/static/icon/upload.png" class="upload" alt="">
                                    <div class="fs16 pl5 col5">Upload </div>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                    <div class="flex-box">
                        <div>
                            <div class="fs16 col5 pb5">{{ $t('phone') }}</div>
                            <!-- :readonly="user.mobile?true:false" -->
                            <el-input class="input mb30" placeholder="enter your phone number" v-model="mobile" ></el-input>
                            
                            <div class=" " style="background: #eee;
    color: #aaa;
    width: 170px;
    height: 32px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;"  v-if="!usernameshow" @click="usernameshow=true,idcardshow=true,banknameshow=true"> View more information</div>
                            <template v-if="usernameshow">
                                <div class="fs16 col5 pb5"> {{ $t('banknumber') }}</div>
                                <!-- :readonly="user.username?true:false" -->
                                <el-input v-if="usernameshow" class="input mb30" placeholder="enter realname" v-model="username" ></el-input>
                                <div class="fs16 col5 pb5"> {{ $t('realname') }}</div>
                                <!-- <div class="fs16 col5 pb5"  v-if="!idcardshow" @click="idcardshow=true"> 查看</div> -->

                                <!-- :readonly="user.idcard?true:false" -->
                                <el-input v-if="idcardshow" class="input mb30" placeholder="enter bank card number" v-model="idcard" ></el-input>

                                <div class="fs16 col5 pb5"> {{ $t('bankname') }}</div>
                                <!-- <div class="fs16 col5 pb5"  v-if="!banknameshow" @click="banknameshow=true"> 查看</div> -->
                                <!-- :readonly="user.bankname?true:false" -->
                                <el-input v-if="banknameshow" class="input mb30" placeholder="enter Bankname" v-model="bankname" ></el-input>
                            
                            </template>
                            
                            <!-- <div class="fs16 col5 pb5">Name</div>
                            <el-input class="input mb30" placeholder="enter your name" v-model="nickname"></el-input> -->
                        </div>
                        <a href="javascript:;" v-if="!user.nickname&&usernameshow" class="btn fs16 colf confirm" @click="saveconfirm">Confirm Save</a>

                        <a href="javascript:;" v-else="" class="btn fs16 colf confirm" @click="usernameshow=false">Hide</a>
                    </div>
                    <!-- <div class="flex-box">
                        <div>
                            <div class="fs16 col5 pb5"> Bank Card Number</div>
                            <el-input class="input" placeholder="enter Bank card number" v-model="idcard"></el-input>
                        </div>
                        <a href="javascript:;" class="btn fs16 colf confirm" @click="tpwd">Confirm Save</a>
                    </div> -->
                    <!-- <div class="fs20 fwb pt70 pb30">Transaction Password Settings</div>
                    <div class="flex-box flex-col-end">
                        <div>
                            <div class="fs16 col5 pb5">Transaction Password</div>
                            <el-input class="input" placeholder="enter your transaction password"  type="password"  v-model="pwd"></el-input>
                        </div>
                        <a href="javascript:;" class="btn fs16 colf confirm" @click="tpwd">Confirm Save</a>
                    </div> -->
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import cookie from "vue-cookies";
import axios from "axios";

export default {
    data() {
        return {
            idcard:'',
            idcardshow:false,
            pwd:'',
            mobile:'',
            
            nickname:'',
            username:'',
            usernameshow:false,


            bankname:'',
            banknameshow:false,
            amount: '',
            birth: '',
            options: [{
                value: 'Male',
                label: 'Male'
            }, {
                value: 'Female',
                label: 'Female'
            },],
            gender: '',
            imageUrl: '',
            avatar:'',
            uploadavatar:'',
            user:{}
        };
    },
    created(){
        this.getuser()
    },
    methods: {
        getuser(){
            this.$api.apiUserInfo({}).then(ret=>{
				if(ret.code==1){

					this.user = ret.data.userinfo;
                    this.nickname=this.user.nickname
                    this.birth=this.user.birthday
                    this.mobile=this.user.mobile
                    this.gender=this.user.gender==1?'Male':(this.user.gender==2?'Female':'')
                    this.avatar=this.user.avatar
                    this.username=this.user.username
                    console.log(this.nickname)
                    this.idcard=this.user.idcard
                    this.bankname=this.user.bankname
                    if(!this.username){
                        this.usernameshow=true
                    }
                    if(!this.bankname){
                        this.banknameshow=true
                    }
                    if(!this.idcard){
                        this.idcardshow=true
                    }
                    console.log(this.user.bankname)
				}
			})
        },
        handleAvatarSuccess(res, file) {
            let formdata = new FormData();

            file.map((item) => {
                //fileList本来就是数组，就不用转为真数组了
                formdata.append("file", item.raw); //将每一个文件图片都加进formdata
            });
            axios.post(axios.defaults.baseURL + "common/upload", formdata).then((res) => {
                if (res.data.msg == "上传成功") {
                    this.avatar = res.data.data.fullurl;
                    this.uploadavatar=res.data.data.fullurl;
                } else {
                    // this.$message.error(res.msg);
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            });m
        },
        beforeAvatarUpload(file) {
            console.log(file)
        },
        saveconfirm(){
            if(!this.nickname){
                // return this.$message.error('昵称不可为空')
                return this.$message({
                        message: "昵称不可为空",
                        type: 'error',
                        offset: window.screen.height / 2
                    })
            }
            if(!this.username){
                // return this.$message.error('真实姓名不可为空')
                return this.$message({
                        message: "真实姓名不可为空",
                        type: 'error',
                        offset: window.screen.height / 2
                    })
            }
            if(!this.bankname){
                // return this.$message.error('开户行不可为空')
                return this.$message({
                        message: '开户行不可为空',
                        type: 'error',
                        offset: window.screen.height / 2
                    })
            }
            if(!this.idcard){
                // return this.$message.error('银行卡号不可为空')
                return this.$message({
                        message: "银行卡号不可为空",
                        type: 'error',
                        offset: window.screen.height / 2
                    })
            }
            var data={avatar:this.uploadavatar,nickname:this.nickname,birthday:this.birth,gender:this.gender,mobile:this.mobile,idcard:this.idcard,username:this.username,bankname:this.bankname}
            // ,idcard:this.idcard
            this.$api.editUserInfo(data).then(ret=>{
                if(ret.code==1){
                    cookie.set("sma_userinfo",ret.data.userinfo);

                    // this.$message.success(ret.msg)
                    this.$message({
						message: ret.msg,
						type: 'success',
						offset: window.screen.height / 2
					})
                    window.location.reload(true)                 
                }else{
                    // this.$message.error(ret.msg)
                    this.$message({
                        message: ret.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        tpwd(){
            if(!this.pwd){
                // return this.$message.error('密码不可为空')
                return this.$message({
                        message: "密码不可为空",
                        type: 'error',
                        offset: window.screen.height / 2
                    })
            }
            this.$api.apiPostPassword({pwd:this.pwd}).then(ret=>{
                if(ret.code==1){
                    // this.$message.success(ret.msg);
                    this.$message({
						message: ret.msg,
						type: 'success',
						offset: window.screen.height / 2
					})
                    this.pwd=''
                }else{
                    // this.$message.error(ret.msg);
                    return this.$message({
                        message: ret.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        }
    }
};
</script>
